<style scoped>
#nosotros,
#bg-mision::before,
#bg-vision::before {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

#bg-mision::before,
#bg-vision::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  filter: grayscale(60%);
}

#bg-mision::before {
  background-image: url("@/assets/mision.jpg");
}
#bg-vision::before {
  background-image: url("@/assets/vision.webp");
}
#nosotros {
  background-attachment: fixed;
  background-image: url("@/assets/fondo-banner.svg");
}
</style>

<template>
  <div
    id="nosotros"
    class="observable pb-[10vh] md:pb-[12vh] min-h-[80vh] md:pt-[12vh] lg:pt-[14vh]"
  >
    <div class="container mx-auto md:rounded-[110px] overflow-hidden">
      <div class="grid grid-cols-1 lg:grid-cols-2 bg-[#6d4300]/30">
        <!-- MISIÓN -->
        <div class="md:min-h-[40vh] flex flex-col py-10 md:py-0">
          <div class="text-center text-4xl my-8 md:mt-24 underline decoration-[#fcbc12]">
            NUESTRA MISIÓN
          </div>
          <div class="text-xl md:text-2xl text-center">
            <p class="px-6">
              Producir y difundir información y entretenimiento con profesionalismo,
              innovación y calidad, ofertando los mejores contenidos a nuestras audiencias
              y el mejor servicio a nuestros anunciantes y patrocinadores.
            </p>
          </div>
        </div>
        <!-- IMAGE MISIÓN -->
        <div class="h-[40vh] overflow-hidden">
          <div
            id="bg-mision"
            class="relative h-full w-full transition-all md:hover:scale-[110%]"
          ></div>
        </div>
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-2 bg-[#6d4300]/30">
        <!-- IMAGE VISIÓN -->
        <div class="h-[40vh] overflow-hidden">
          <div
            id="bg-vision"
            class="relative h-full w-full transition-all md:hover:scale-[110%]"
          ></div>
        </div>
        <!-- VISIÓN -->
        <div
          class="md:min-h-[40vh] order-first lg:order-last flex flex-col py-10 md:py-0"
        >
          <div class="text-center text-4xl md:mt-24 underline decoration-[#fcbc12]">
            NUESTRA VISIÓN
          </div>
          <div class="text-xl md:text-2xl text-center mt-8 md:mt-16">
            <p class="px-6">
              Ser la multiplataforma líder de noticias y entretenimiento, referente
              regional con una contribución valiosa y positiva a la sociedad a la que
              servimos, respetando la pluralidad de ideas, sin discriminación alguna.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
