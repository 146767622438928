import { unref } from "vue";

/**
 * Obtener la url del asset especificado.
 * 
 * @param {*} filename 
 * @returns 
 */
export function getImageUrl(filename) {
 
    filename = unref(filename);

    return require(`@/assets/${filename}`);
}