<template>
  <button
    :type="type"
    class="px-4 py-2 text-2xl font-medium text-center text-white transition-colors duration-150 bg-amber-500 border border-transparent hover:border-gray-400 rounded-lg active:bg-amber-600 hover:bg-amber-600 focus:outline-none focus:ring shadow-md hover:shadow-lg hover:-translate-x-1 hover:-translate-y-1"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "submit",
    },
  },
};
</script>

<style scoped>
button {
  transition: all 0.1s ease-in-out;
}
</style>
