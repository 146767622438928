<script setup>
import { ref } from "vue";

/**
 * Los eventos que emite el componente.
 */
defineEmits(["anchorClicked"]);

/**
 * El ancla activa según el cuerpo del documento.
 */
const props = defineProps({
  active: String,
});

/**
 * Indicador de si el menú colapsable está visible.
 */
let showMenu = ref(false);

/**
 * Ocultar o mostrar el menú colapsable.
 */
const toggleNav = () => (showMenu.value = !showMenu.value);

/**
 * Los enlaces de navegación.
 */
const navLinks = [
  { title: "Inicio", anchor: "inicio" },
  { title: "Estaciones", anchor: "estaciones" },
  { title: "Cobertura", anchor: "cobertura" },
  { title: "Nosotros", anchor: "nosotros" },
  { title: "Alianzas", anchor: "alianzas" },
];
</script>

<template>
  <div class="z-[100] bg-[#fcbc12] sticky top-0">
    <nav class="container px-6 mx-auto md:flex md:justify-between md:items-center">
      <div class="flex items-center justify-between">
        <a
          href="/"
          class="text-xl font-bold text-gray-100 md:text-2xl hover:text-indigo-400"
        >
          <img
            alt="Logotipo Álica Medios"
            src="@/assets/logo.png"
            class="w-[190px] md:w-[220px] hover:opacity-100 opacity-80"
          />
        </a>
        <!-- Mobile menu button -->
        <div @click="toggleNav" class="flex md:hidden">
          <button
            type="button"
            class="text-gray-100 hover:text-gray-400 focus:outline-none focus:text-gray-400"
          >
            <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
              <path
                fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
      <ul
        :class="showMenu ? 'flex' : 'hidden'"
        class="flex-col transition-all text-center mt-8 space-y-4 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-5 lg:space-x-10 md:mt-0 md:pb-0 pb-5"
      >
        <li
          class="navbar-link md:text-[1.1rem] lg:text-[1.2rem] transition-transform transition-colors hover:scale-125"
          :class="{ 'scale-125': link.anchor === active }"
          v-for="link in navLinks"
          :key="link.anchor"
        >
          <a
            :href="`#${link.anchor}`"
            @click="
              () => {
                toggleNav();
                $emit('anchorClicked', link.anchor);
              }
            "
            :class="{ 'active-link': link.anchor === active }"
            class="transition-all"
            >{{ link.title }}</a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<style scoped>
li.navbar-link {
  /* text-white hover:text-indigo-400; */
  color: white;
  text-align: center;
}

li.navbar-link:hover,
.active-link {
  color: #491f00;
}
</style>
