<script setup>
import FooterBar from "./components/FooterBar.vue";
import MainBanner from "./components/MainBanner.vue";
import MapaCobertura from "./components/MapaCobertura.vue";
import NavigationBar from "./components/NavigationBar.vue";
import NuestrasAlianzas from "./components/NuestrasAlianzas.vue";
import NuestrasEstaciones from "./components/NuestrasEstaciones.vue";
import QuienesSomos from "./components/QuienesSomos.vue";
import { onBeforeMount, onMounted, ref } from "vue";

/**
 * El enlace activo según el área mostrada en pantalla.
 */

const activeAnchor = ref("inicio");

/**
 * El observador de intersección de elementos en la página.
 */
const observer = new IntersectionObserver(
  function (entries) {
    if (entries[0].isIntersecting === true) activeAnchor.value = entries[0].target.id;
  },
  {
    threshold: 0.6,
  }
);

/**
 * Observar la intersección de los elementos al montar el componente.
 */
onMounted(() => {
  document.querySelectorAll(".observable").forEach((el) => observer.observe(el));
});

/**
 * Predefinir el ancla activa al montar el componente.
 */
onBeforeMount(() => {
  let anchor = window.location.href.split("#");
  if (anchor.length == 2) {
    activeAnchor.value = anchor[1];
  }
});
</script>

<template>
  <NavigationBar
    :active="activeAnchor"
    @anchor-clicked="(anchor) => (activeAnchor = anchor)"
  />

  <div>
    <MainBanner />
    <NuestrasEstaciones />
    <MapaCobertura />
    <QuienesSomos />
    <NuestrasAlianzas />

    <FooterBar />
  </div>
</template>

<style>
#app {
  font-family: "Josefin Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
